import { ChargeCategory } from "constants/charge/ChargeCategory";
import { ChargeTypeMaintenanceConstant } from "presentation/constant/ChargeType/ChargeTypeMaintenanceConstant";
import { chargeCategoryCheckboxOption } from "presentation/constant/CheckBox/StaticCheckboxOptions";
import { useChargeTypeMaintenanceVM } from "presentation/hook/ChargeType/useChargeTypeMaintenanceVM";
import { useChargeTypeMaintenanceTracked } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useEffect, useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ChargeTypeEditPanelTitleBar from "./ChargeTypeEditPanelTitleBar";
export const ChargeTypeMaintenanceFormPanel = () => { 
    const [chargeTypeState] = useChargeTypeMaintenanceTracked(); 
    const chargeTypeVM = useChargeTypeMaintenanceVM(); 
    const {currentSelectedRow, masterState} = chargeTypeState; 
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState; 
    const SCREEN_CONSTANT = ChargeTypeMaintenanceConstant; 

    const currentEntity = isRead? currentSelectedRow : editingEntity; 


    useEffect(() => {
        const refreshValueCode = async()=>{

            currentEntity.chargeCategory&&chargeTypeVM.refreshValueCode(currentEntity.chargeCategory);
        }    
        if(currentEntity.chargeCategory){
            refreshValueCode();
        }
            
    },[chargeTypeVM, currentEntity.chargeCategory])

    const memoChargeType = useMemo(() => 
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}> 
            <NbisCommonField 
                errorMessages={allFormState} 
                isReadOnly={!isAdd} 
                isShowOptional={false} 
                readOnlyValue={currentEntity?.chargeType || ''} 
                fieldValue={currentEntity?.chargeType} 
                fieldLabel={SCREEN_CONSTANT.CHARGE_TYPE} 
                isSaveClicked={isSaveClicked} 
                fieldType={FieldType.TEXT} 
                fieldKey={'chargeType'} 
                isShowMissingError={true} 
                maxLength={60} 
                requiredFieldList={[]} 
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                    chargeTypeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
        </div> 
        , [allFormState, isAdd, currentEntity?.chargeType, SCREEN_CONSTANT.CHARGE_TYPE, isSaveClicked, chargeTypeVM]) 

        const memoSubChargeType = useMemo(() => 
            <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={!isAdd||currentEntity.chargeCategory==="USER"} 
                    isShowOptional={false} 
                    readOnlyValue={currentEntity?.subChargeType || ''} 
                    fieldValue={currentEntity?.subChargeType} 
                    fieldLabel={SCREEN_CONSTANT.SUB_CHARGE_TYPE} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.TEXT} 
                    fieldKey={'subChargeType'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        chargeTypeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
            </div> 
            , [allFormState, isAdd, currentEntity.chargeCategory, currentEntity?.subChargeType, SCREEN_CONSTANT.SUB_CHARGE_TYPE, isSaveClicked, chargeTypeVM]) 
        const memoDesc = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"330px", marginBottom:"24px"}}> 
                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={!(isEditable || isAdd)} 
                    isShowOptional={false} 
                    readOnlyValue={currentEntity?.chargeTypeDesc || ''} 
                    fieldValue={currentEntity?.chargeTypeDesc} 
                    fieldLabel={SCREEN_CONSTANT.DESC} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.TEXT} 
                    fieldKey={'chargeTypeDesc'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        chargeTypeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />       
            </div> 
        , [SCREEN_CONSTANT.DESC, allFormState, chargeTypeVM, currentEntity?.chargeTypeDesc, isAdd, isEditable, isSaveClicked]) 

        const memoType = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}> 
                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={currentEntity.chargeCategory==="SYS"} 
                    isShowOptional={false} 
                    readOnlyValue={currentEntity?.chargeCategory || ''} 
                    fieldValue={currentEntity?.chargeCategory} 
                    fieldLabel={SCREEN_CONSTANT.CATEGORY} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.CHECKBOX_GROUP} 
                    fieldKey={'chargeCategory'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    options={chargeCategoryCheckboxOption?.filter(e => e.key !== ChargeCategory.SYS)}
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        chargeTypeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />  

            </div> 
        , [SCREEN_CONSTANT.CATEGORY, allFormState, chargeTypeVM, currentEntity.chargeCategory, isSaveClicked]) 

        const memoCountFspInd = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"120px", marginBottom:"24px"}}> 
                <NbisCommonField
                    fieldKey={"countFspInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.COUNT_FSP_IND} 
                    fieldType={FieldType.TEXT} 
                    isReadOnly={true}
                    onFieldChange={()=>{}}/>
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.countFspInd ==='Y'}
                    disabled={!(isEditable || isAdd)}
                    onChange={(e) => chargeTypeVM.onCheckboxChange(e.checked, "countFspInd")}/>
            </div> 
        , [SCREEN_CONSTANT.COUNT_FSP_IND, chargeTypeVM, currentEntity?.countFspInd, isAdd, isEditable]) 

        const memoTputInd = useMemo(() => 
            <> 
                <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    fieldKey={"tputStatisticInd"} 
                    requiredFieldList={[]} 
                    fieldLabel={SCREEN_CONSTANT.TPUT_IND}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEntity?.tputStatisticInd==='Y'} 
                    disabled={!(isEditable || isAdd)} 
                    onChange={(e) => chargeTypeVM.onCheckboxChange(e.checked, "tputStatisticInd")} 
                /> 
            </div> 
            </> 
        , [SCREEN_CONSTANT.TPUT_IND, currentEntity?.tputStatisticInd, isAdd, isEditable, chargeTypeVM]) 

        const memoActiveInd = useMemo(() => 
            <> 
                <div className='im-flex-row-item' style={{ width: "100px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    fieldKey={"activeInd"} 
                    requiredFieldList={[]} 
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEntity?.activeInd==='Y'} 
                    disabled={!(isEditable || isAdd)} 
                    onChange={(e) => chargeTypeVM.onCheckboxChange(e.checked, "activeInd")} 
                /> 
            </div> 
            </> 
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isAdd, isEditable, chargeTypeVM]) 


        

    const memoEditPanelTitleBar = useMemo(() => { 
        return <ChargeTypeEditPanelTitleBar/> 
    }, []) 
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'> 
        {/* Header part of add or edit record */} 
        {memoEditPanelTitleBar} 
        {/* Readable and Editable Form */} 
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}> 
                <div className="flex-row-auto"> 
                    <div className={'flex-row-item'}> 
                        <div className={'im-flex-row'}> 
                            <div className={'flex-row-item-stretch'}> 
                                <SidebarTitle>{!isAdd ? currentEntity.chargeType : "NEW CHARGE"}</SidebarTitle> 
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>} 
                            </div> 
                        </div> 

                        <CriteriaItemContainer> 
                            {memoChargeType} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoSubChargeType} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoDesc} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoType} {memoCountFspInd} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoTputInd}   {memoActiveInd} 
                        </CriteriaItemContainer> 
                    </div> 
                </div> 
            </div> 
        {/* Readable and Editable Form */} 
    </StyledSidebar> 
} 
