import { useChargeTypeMaintenanceVM } from "presentation/hook/ChargeType/useChargeTypeMaintenanceVM";
import { useChargeTypeMaintenanceTracked } from "presentation/store/ChargeType/ChargeTypeMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ChargeTypeTablePanel from "./ChargeTypeTablePanel";
const ChargeTypeMaintenance:React.FC = () => { 
    const [isLoading, setIsLoading] = useState(false); 
    const [chargeTypeState] = useChargeTypeMaintenanceTracked(); 
    const chargeTypeVM = useChargeTypeMaintenanceVM(); 
    const {isBackMaster} = chargeTypeState; 

    useEffect(() => { 
        const initialScreen = async() => {    
            setIsLoading(true); 
            try {      
                const results = await Promise.allSettled([ 
                    chargeTypeVM.onSearch(),
                    chargeTypeVM.loadDropdownOption(), 
                ]); 
                results?.forEach((result, index) => { 
                    if (index === 0 && result.status === 'fulfilled') { 
 
                    }  
                }) 
            } catch(error) { 
                setIsLoading(false); 
            } 
        } 
        initialScreen().then(() => { 
            setIsLoading(false) 
        }).catch(error => {             
            setIsLoading(false) 
        }); 

    }, [isBackMaster,chargeTypeVM]) 

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />; 

    return <> 
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}> 
            <SliderPanel 
                isOpen={false} 
                draggable={false} 
                leftSectionWidth={"0%"} 
                rightSectionWidth={"100%"} 
                leftChildren={<ChargeTypeTablePanel/>} 
                /> 
        </div> 
    </> 
} 
export default memo(ChargeTypeMaintenance); 
