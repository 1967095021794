import { ChargeTypeEntity, EMPTY_CHARGE_TYPE_ENTITY } from "domain/entity/ChargeType/ChargeTypeEntity";
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import _ from "lodash";
import { createChargeTypeMaintenanceValidationSchema } from "presentation/constant/ChargeType/ChargeTypeMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { ChargeTypeMaintenanceModel } from "presentation/model/ChargeType/ChargeTypeMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { GroupCheckboxList, IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
interface ChargeTypeMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<ChargeTypeMaintenanceModel>> | ((value: SetStateAction<ChargeTypeMaintenanceModel>) => void),
    ],
    repo: ChargeTypeRepository,
}
export const ChargeTypeMaintenanceVM = ({ dispatch, repo }: ChargeTypeMaintenanceVMProps) => {
    const [chargeTypeMainDispatch] = dispatch;

    const loadDropdownOption = async () => {
    }
    const onSearch = async () => {
        const entities = await repo.getAllChargeTypes();
        chargeTypeMainDispatch(prevState => ({
            ...prevState,
            tableData: entities,
            selectedRows: [],
            currentSelectedRow: EMPTY_CHARGE_TYPE_ENTITY,
            isBackMaster: false,
        }))
    }
    const updateSelectedRows = async (selecedRows: ChargeTypeEntity[]) => {
        chargeTypeMainDispatch(prevState => {

            return {
                ...prevState,
                selectedRows: selecedRows,
            }
        })
    }
    const onAdd = () => {
        chargeTypeMainDispatch(prevState => {

            return {
                ...prevState,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: true,
                    isEditable: false,
                    isRead: false,
                    editingEntity: EMPTY_CHARGE_TYPE_ENTITY,
                }
            }
        })
    }
    const onEdit = (currentEntity: ChargeTypeEntity) => {
        chargeTypeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onEditClick = (currentEntity: ChargeTypeEntity) => {
        chargeTypeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: currentEntity,
                isShowEditPanel: true,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: true,
                    isRead: false,
                    editingEntity: {
                        ...currentEntity
                    },
                }
            }
        })
    }

    const onReset = () => {
        chargeTypeMainDispatch(prevState => {
            const resetEntity = prevState.masterState.isAdd ? EMPTY_CHARGE_TYPE_ENTITY : prevState.currentSelectedRow;
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...resetEntity
                    },
                }
            }
        })
    }
    const onClose = () => {
        chargeTypeMainDispatch(prevState => {
            return {
                ...prevState,
                currentSelectedRow: EMPTY_CHARGE_TYPE_ENTITY,
                selectedRows: [],
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    editingEntity: EMPTY_CHARGE_TYPE_ENTITY,
                    allFormState: {}
                },
                isBackMaster: true,
                isShowEditPanel: false,
            }
        })
    }
    const onSaveClicked = () => {
        chargeTypeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }
    const onSave = async (currentEntity: ChargeTypeEntity, isAdd: boolean) => {
        const valResult = isAdd ? await Validation(createChargeTypeMaintenanceValidationSchema).ValidateFormOnly(currentEntity) : null;
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...valResult, warningMessage: 'Please input the missing value.' };

            chargeTypeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            const res: ResponseEntity = {
                code: "",
                success: false,
                msg: null,
                data: 'Please input the missing value.'
            }

            return res;
        }
        if (isAdd) {
            return await repo.createNewChargeType(currentEntity);
        } else {
            return await repo.updateChargeType(currentEntity);
        }
    }
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        chargeTypeMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }
    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        if (fieldKey === 'chargeType' || fieldKey === 'subChargeType') {
            val = val.toUpperCase().replace(/\s+/g, '');
        }
        chargeTypeMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val,
                    },
                    allFormState: {
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[], fieldName: string) => {
        let selectedValue: string[] = [];
        if (e) {
            e?.forEach(function (value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        chargeTypeMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: selectedValue,
                },
                allFormState: {
                    ...prevState.masterState.allFormState,
                    [fieldName]: '',
                }
            }
        }));
    };

    const refreshValueCode = async (chargeCategory: string) => {
        if (chargeCategory === "USER") {
            chargeTypeMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        editingEntity: {
                            ...prevState.masterState.editingEntity,
                            subChargeType: null,
                        },
                    }
                }
            })
        }
    }
    return {
        refreshValueCode: refreshValueCode,
        loadDropdownOption: loadDropdownOption,
        updateSelectedRows: updateSelectedRows,
        onAdd: onAdd,
        onEdit: onEdit,
        onReset: onReset,
        onClose: onClose,
        onSearch: onSearch,
        onSaveClicked: onSaveClicked,
        onSave: onSave,
        onCheckboxChange: onCheckboxChange,
        onFieldChange: onFieldChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
        onEditClick: onEditClick,
    }
} 
